import { locale } from "moment";
import $ from "jquery";
import AOS from "aos";

$(document).ready(function () {
    const html = $("html");
    var lang = html.attr("lang");
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let myVar = setInterval(myTimer, 1000);
    const monthNames = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
    ];
    const dateObj = new Date();
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, "0");
    const dayname = days[dateObj.getDay()];
    const year = dateObj.getFullYear();

    const output = day + "/" + month + "/" + year + " " + "(" + dayname + ") ";

    AOS.init();
    if (lang == "") {
        locale("km");
    } else {
        if (lang == "kh") {
            locale("km");
        } else {
            locale(lang);
        }
    }
    // Dropdown nav
    $("#btn_nav").click(function () {
        $(".dropdown_nav").slideToggle(500);
    });
    $(".dropdown_nav .textmenu").click(function () {
        $(".dropdown_nav").slideUp(500);
    });

    // Dropdown lang
    var lang = $("#language").hide();
    $(".lang").click(function () {
        $(this).parent().find("#language").toggle();
    });

    // Slide
    var colors = "#fff";
    $(".sport-slide").owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        margin: 10,
        stagePadding: 50,
        autoplay: {
            delay: 9000,
            disableOnInteraction: false,
        },
        navText: [
            `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="39.547" viewBox="0 0 24 39.547">
                <path id="Path_53" data-name="Path 53" d="M28.131,58.968l17.6,17.6a2.173,2.173,0,0,0,3.074,0l2.053-2.053a2.173,2.173,0,0,0,0-3.07L36.913,57.431,50.861,43.417a2.173,2.173,0,0,0,0-3.07l-2.053-2.053a2.173,2.173,0,0,0-3.074,0l-17.6,17.6A2.174,2.174,0,0,0,28.131,58.968Z" transform="translate(-27.494 -37.657)" fill=${colors} />
            </svg>`,
            `<svg svg xmlns = "http://www.w3.org/2000/svg" width = "24" height = "39.547" viewBox = "0 0 24 39.547" >
                <path id="Path_53" data-name="Path 53" d="M50.858,58.968l-17.6,17.6a2.173,2.173,0,0,1-3.074,0l-2.053-2.053a2.173,2.173,0,0,1,0-3.07L42.076,57.431,28.127,43.417a2.173,2.173,0,0,1,0-3.07l2.053-2.053a2.173,2.173,0,0,1,3.074,0l17.6,17.6A2.173,2.173,0,0,1,50.858,58.968Z" transform="translate(-27.494 -37.657)" fill=${colors} />
            </svg>`,
        ],
        responsive: {
            0: {
                items: 1,
                stagePadding: 0,
            },
            300: {
                items: 1,
                stagePadding: 0,
            },
            600: {
                items: 3,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    });
    $(".owl-carousel").find(".owl-nav").removeClass("disabled");
    $(".owl-carousel").on("changed.owl.carousel", function (event) {
        $(this).find(".owl-nav").removeClass("disabled");
    });
    $(".main-slide").owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        pagination: false,
        stagePadding: 0,
        autoplay: {
            delay: 9000,
            disableOnInteraction: false,
        },
        responsive: {
            0: {
                items: 1,
                stagePadding: 0,
            },
            300: {
                items: 1,
                stagePadding: 0,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    });

    // Date
    function myTimer() {
        const d = new Date();
        document.getElementById("ct5").innerHTML = d.toLocaleTimeString();
    }
    $("#datenow").text(output);
});
